import { createWebHistory, createRouter } from 'vue-router'
import mainRoutes from './main'

const routes = mainRoutes

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router;
