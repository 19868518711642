<template style="background: #F2F2F2;">

    <div class="container" style="display: flex;justify-content: center;align-items: center;height: 100vh;">

        <div class="form">
            <v-window v-model="step">

                <img src="https://supersistemasweb.com/acext/icons/mihard.webp" alt="mihard logo"
                    style="width: 50px; margin: 0 auto;">

                <v-window-item :value="1" style="margin: 2rem 0">
                    <p class="text-surcharge-applied">Primer paso: identificacion</p>

                    <ValidateAccount @retrieve-id="saveUserID" class="mb-4" />

                    <a href="https://api.whatsapp.com/send/?phone=5491122828112&text&app_absent=0" target="_blank">¿No
                        podes acceder? Ayuda</a>
                </v-window-item>

                <v-window-item :value="2">

                    <Alert v-if="complete" />
                    <div v-else>

                        <p class="text-surcharge-applied" style="height: .9rem;">Segundo paso: informacion de tu tienda
                            personal</p>
                        <p class="text-surcharge-applied subtext mb-4">Los campos marcados con un (*) seran visibles en
                            tu
                            web
                        </p>

                        <form @submit="onSubmit">

                            <!-- Nombre -->
                            <div class="mb-3">
                                <label class="form-label" for="nombre">Nombre</label>
                                <input type="text" class="form-control" name="nombre" v-bind="nombreAttrs"
                                    v-model="nombre">
                                <span style="color:red;">{{ errors.nombre }}</span>
                            </div>

                            <!-- Slogan -->
                            <div class="mb-3">
                                <label class="form-label" for="slogan">Slogan*</label>
                                <input type="text" class="form-control" name="slogan" v-bind="sloganAttrs"
                                    v-model="slogan">
                                <span style="color:red;">{{ errors.slogan }}</span>
                            </div>

                            <!-- Mail -->
                            <div class="mb-3">
                                <label class="form-label" for="mail">Correo Electrónico*</label>
                                <input type="email" class="form-control" name="mail" v-bind="mailAttrs" v-model="mail">
                                <span style="color:red;">{{ errors.mail }}</span>
                            </div>

                            <!-- Dirección -->
                            <div class="mb-3">
                                <label class="form-label" for="direccion">Dirección*</label>
                                <input type="text" class="form-control" name="direccion" v-bind="direccionAttrs"
                                    v-model="direccion">
                                <span style="color:red;">{{ errors.direccion }}</span>
                            </div>

                            <!-- Telefonos -->
                            <div class="mb-3">
                                <label class="form-label" for="telefonos">Telefonos*</label>
                                <input type="text" class="form-control" name="telefonos" v-bind="telefonosAttrs"
                                    v-model="telefonos">
                                <span style="color:red;">{{ errors.Telefonos }}</span>
                            </div>

                            <!-- Hostname -->
                            <div class="mb-3">
                                <label class="form-label" for="hostname" style="cursor: pointer;">
                                    Hostname
                                    <i class="bi bi-exclamation-circle-fill"></i>
                                    <v-tooltip activator="parent" location="end" width="300px"> A traves de el podras
                                        acceder a tu web, por ejemplo: <span
                                            style="text-decoration: underline;">https://<b
                                                style="color:#fff;">tuhostname</b>.mihard.com/</span></v-tooltip>
                                </label>
                                <input type="text" class="form-control" name="hostname" v-bind="hostnameAttrs"
                                    v-model="hostname">
                                <span style="color:red;">{{ errors.hostname }}</span>
                            </div>

                            <button class="ty-btn ty-btn__primary login" :disabled="isSubmitting">{{ isSubmitting ?
                                'CARGANDO...' :
                                'CREAR WEB' }}</button>
                        </form>
                    </div>
                </v-window-item>


            </v-window>
        </div>
    </div>

</template>

<style scoped>
@import '../assets/styles.css';
@import '../assets/standalone.css';
@import 'bootstrap-icons/font/bootstrap-icons.css';

@media (max-width: 1000px) {

    .form {
        width: 90% !important;
    }

    .actions-container {
        display: flex;
        flex-direction: column;
    }
}

body {
    margin: 0;
    background-color: #F2F2F2;
}


/**
 * Render the `main` element consistently in IE.
 */

main {
    display: block;
    background-color: #F2F2F2;

}



.text-surcharge-applied.subtext {
    font-weight: unset !important;
    font-size: .5rem;
    text-align: center;
    margin-bottom: 1rem !important;
}

.text-surcharge-applied {
    text-transform: uppercase;
    font-size: .7rem;
    letter-spacing: 1px;
    font-weight: bold;
    margin: unset !important;
    padding: unset !important;
}

.form {
    min-width: 35%;
    background: #fff;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.actions-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    height: 100%;
    flex-wrap: wrap;
}

.button-action {
    height: 7rem !important;
    width: 7rem;
    font-size: 3rem;
}

.select-option-step {
    display: flex;
    align-items: center;
    justify-content: center;
}

.select-option-step.step2 {
    height: 80%;
    flex-direction: column;
}

.agroup-buttons {
    display: flex;
    gap: 1rem;
}

.v-data-table-header__content>span {
    font-weight: bold;
}

.isDisplayingDatatable {
    height: 40rem;
    width: 60rem;
}

.isDisplayingMenu {
    height: 35rem;
}

.v-btn__content {
    text-wrap: wrap !important;
}

.actions-section-one {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 80%;
    justify-content: center;
}
</style>

<script setup>
import Alert from '@/components/SuccessfullyAlert.vue';
import ValidateAccount from '@/components/ValidateAccountComponent.vue';
import schemas from "../utils/validations.js";
import { useForm } from 'vee-validate';
import { ref } from 'vue'

const saveUserID = (id) => {
    console.log("🚀 ~ saveUserID ~ id:", id)
    userID.value = id
    step.value = 2
}

const { errors, handleSubmit, isSubmitting, defineField } = useForm({ validationSchema: schemas.webClient });
const [nombre, nombreAttrs] = defineField('nombre');
const [slogan, sloganAttrs] = defineField('slogan');
const [mail, mailAttrs] = defineField('mail');
const [direccion, direccionAttrs] = defineField('direccion');
const [telefonos, telefonosAttrs] = defineField('telefonos');
const [hostname, hostnameAttrs] = defineField('hostname');
const complete = ref(false)
//eslint-disable-next-line
const onSubmit = handleSubmit(async (values, actions) => {
    const uppercaseValues = {};
    Object.keys(values).forEach(key => {
        uppercaseValues[key.toUpperCase()] = values[key];
    });

    console.log("🚀 ~ onSubmit ~ values:", values)
    fetch("https://api.mihard.com/api/admin/alta-mihard/crear-website", {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${userID.value}`
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({ data: uppercaseValues })
    })
        .then((response) => {
            if (response.ok) {
                complete.value = true
            }
        })
        .catch((error) => console.log(error))
})

let step = ref(0)
let userID = ref(null);


</script>