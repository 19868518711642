<template>

    <form @submit="onSubmit">

        <div class="mb-3">
            <label class = "form-label" for="email">Email</label>
            <input type="text" class="form-control" name="email" v-bind="emailAttrs" v-model="email">
            <span style="color:red;">{{ errors.email }}</span>
        </div>

    
        <div v-if="login" class="mb-3">
            <label class = "form-label" for="password">Contraseña</label>
            <input type="password" class="form-control" name="password" v-bind="passwordAttrs" v-model="password">
            <span style="color:red;">{{ errors.password }}</span>
        </div>

        <button class="ty-btn ty-btn__primary login" :disabled="isSubmitting">{{ isSubmitting ? 'CARGANDO...' :
            'VALIDAR' }}</button>
    </form>

</template>

<script setup>
import { useForm } from 'vee-validate';
import schemas from "../utils/validations.js";
import { defineEmits, ref } from 'vue'


const { errors, handleSubmit, isSubmitting, defineField } = useForm({ validationSchema: schemas.email });
const [email, emailAttrs] = defineField('email');
const [password, passwordAttrs] = defineField('password');
const login = ref(false)
//eslint-disable-next-line
const emit = defineEmits(['retrieveId']);
const onSubmit = handleSubmit(async (values, actions) => {
    console.log("🚀 ~ onSubmit ~ values:", values)

    if (!login.value) {
        fetch("https://api.mihard.com/api/alta-mihard/validar-cuenta", {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(values)
        })
            .then(async (response) => {
                const jsonResponse = await response.json()

                if (!response.ok) {
                    let mensaje = "Ha ocurrido un error inesperado, intentelo denuevo mas tarde"
                    actions.setFieldError("email", mensaje)
                    throw new Error(mensaje)
                }

                console.log("🚀 ~ .then ~ jsonResponse:", jsonResponse)

                if (jsonResponse.id !== null) {
                    login.value = true
                } else {
                    actions.setFieldError("email", "La cuenta no existe")

                }
            })
            .catch((error) => console.log(error))
    } else {
        fetch("https://api.mihard.com/api/alta-mihard/existe-webcliente", {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(values)
        })
            .then(async (response) => {

                if (!response.ok) {
                    let responseText = await response.text()
                    actions.setFieldError("password", "Ha ocurrido un error inesperado")
                    throw new Error(responseText)
                }

                const jsonResponse = await response.json()

                if (jsonResponse.existWebClient === -1) {
                    actions.setFieldError("password", "Credenciales invalidas")
                } else if (jsonResponse.existWebClient) {
                    actions.setFieldError("password", "Esta cuenta ya tiene asociada una web")
                } else {
                    emit("retrieveId", jsonResponse.id)
                }

            })
            .catch((error) => console.log(error))
    }
});



</script>