<template>
  <v-app class="application">
    <main>
      <RouterView />
    </main>
  </v-app>
</template>

<style>
</style>
