<template>
    <div class="contenedor">
        <v-empty-state headline="Oops, 404" title="Pagina no encontrada" text="La pagina que estas buscando no existe"
            image="https://supersistemasweb.com/acext/icons/attention.webp"></v-empty-state>

        <a href = "/">Volver a mihard</a>

    </div>

</template>

<style scoped>
.contenedor {
    margin-top: auto;
    height: 90vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
</style>
