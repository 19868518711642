import Home from "@/views/HomeView.vue";
import Alta from "@/views/AltaView.vue";
import PageNotFound from "@/views/PageNotFound.vue";

export default [
  {
    path: "/",
    component: Home,
  },
  {
    path:"/alta",
    component: Alta
  },
  {
    path: '/:catchAll(.*)',
    component: PageNotFound
  }
];
