<template>
  <!-- Preloader Start -->
  <div id="preloader-active">
    <div class="preloader d-flex align-items-center justify-content-center">
      <div class="preloader-inner position-relative">
        <div class="preloader-circle"></div>
        <div class="preloader-img pere-text"> <img src="../assets/img/logo/logo.png" alt=""> </div>
      </div>
    </div>
  </div>
  <!-- Preloader Start -->
  <header>
    <!-- Header Start -->
    <div class="header-area">
      <div class="main-header ">
        <div class="header-top top-bg d-none d-lg-block">
          <div class="container-fluid">
            <div class="col-xl-12">
              <div class="row d-flex justify-content-between align-items-center">
                <div class="header-info-left">
                  <ul>
                    <li><i class="fas fa-map-marker-alt"></i>Argentina</li>
                    <li><i class="fas fa-envelope"></i>info@mihard.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-bottom header-sticky">
          <div class="container-fluid">
            <div class="row align-items-center">
              <!-- Logo -->
              <div class="col-xl-2 col-lg-1 col-md-1">
                <div class="logo"> <a href="index.html"><img src="../assets/img/logo/logo.png" alt=""></a> </div>
              </div>
              <div class="col-xl-8 col-lg-8 col-md-8">
                <!-- Main-menu -->
                <div class="main-menu f-right d-none d-lg-block">
                  <nav>
                    <ul id="navigation">
                      <li><a href="index.html">Home</a></li>
                      <li>
                        <router-link :to="{ path: '/alta' }">Login</router-link>
                      </li>
                      <li><a href="ayuda.html">Ayuda y Preguntas Frecuentes</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="col-xl-2 col-lg-3 col-md-3">
                <div class="header-right-btn f-right d-none d-lg-block"> <a href="https://api.whatsapp.com/send/?phone=5491122828112&text&app_absent=0"
                    class="btn header-btn">Contactanos</a> </div>
              </div>
              <!-- Mobile Menu -->
              <div class="col-12">
                <div class="mobile_menu d-block d-lg-none"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Header End -->
  </header>

  <!-- slider Area Start-->
  <div class="slider-area ">
    <!-- Mobile Menu -->
    <div class="slider-active">
      <div class="single-slider slider-height d-flex align-items-center" style="  background-image: url('https://supersistemasweb.com/web_cliente/assets/img/hero/h1_hero.jpg');
  background-size: cover;
  background-repeat: no-repeat;">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-8">
              <div class="hero__caption">
                <p data-animation="fadeInLeft" data-delay=".4s">Bienvenido a MiHard</p>
                <h1 data-animation="fadeInLeft" data-delay=".6s">Tenes local de computación?</h1>
                <h1 data-animation="fadeInLeft" data-delay="2s">Obtené tu ecommerce!</h1>
                <!-- Hero-btn -->

                <a href="https://api.whatsapp.com/send/?phone=5491122828112&text&app_absent=0">
                  <div class="hero__btn" data-animation="fadeInLeft" data-delay=".1s">
                    <span class="btn hero-btn">LO QUIERO</span>
                  </div>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- slider Area End--> <!-- We Trusted Start-->
  <div class="we-trusted-area trusted-padding">
    <div class="container">
      <div class="row d-flex align-items-end">
        <div class="col-xl-7 col-lg-7">
          <div class="trusted-img"> <img src="../assets/img/team/wetrusted.jpg" alt=""> </div>
        </div>
        <div class="col-xl-5 col-lg-5">
          <div class="trusted-caption">
            <h2>No lo Dudes! <br> más de 350 resellers usan nuestro servicio</h2>
            <p>MiHard es un ecommerce totalmente personalizable, tu teléfono, mail, el nombre de tu negocio y hasta un
              slogan, son algunas de las cosas que podes incluir..</p>
            <a href="https://www.mihard.com/ayuda.html" class="btn trusted-btn">Ver más...</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- We Trusted End--> <!-- services Area Start-->
  <div class="services-area section-padding2">
    <div class="container">
      <!-- section tittle -->
      <div class="row">
        <div class="col-lg-12">
          <div class="section-tittle text-center">
            <h2>Nuestro Servicio</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6">
          <div class="single-services text-center mb-30">
            <div class="services-icon"> <span class="flaticon-checklist"></span> </div>
            <div class="services-caption">
              <h4>Ecommerce</h4>
              <p>Servicio de ecommerce personalizable.</p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6">
          <div class="single-services text-center mb-30">
            <div class="services-icon"> <span class="flaticon-audit"></span> </div>
            <div class="services-caption">
              <h4>Próximamente: Gestión de Stock</h4>
              <p>App Cloud de gestión de stock.</p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6">
          <div class="single-services text-center mb-30">
            <div class="services-icon"> <span class="flaticon-checklist-1"></span> </div>
            <div class="services-caption">
              <h4>Próximamente: Facturación Electrónica</h4>
              <p>Gestión de factura electrónica AFIP desde App Cloud.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import '../assets/bootstrap.min.css';
@import '../assets/owl.carousel.min.css';
@import '../assets/flaticon.css';
@import '../assets/slicknav.css';
@import '../assets/animate.min.css';
@import '../assets/magnific-popup.css';
@import '../assets/fontawesome-all.min.css';
@import '../assets/themify-icons.css';
@import '../assets/slick.css';
@import '../assets/nice-select.css';
@import '../assets/style.css';
</style>
