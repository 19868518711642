import * as yup from 'yup';

let schemas = {
    email: yup.object({
        email: yup.string().email("Email invalido").required("Este campo no puede estar vacio")
    }),
    login: yup.object({
        username: yup.string().required("Este campo no puede estar vacio"),
        password: yup.string().required("Este campo no puede estar vacio")
        //.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/, "La CLAVE debe tener al menos una letra mayúscula, una letra minúscula, un dígito y un mínimo de 6 caracteres")
    }),
    webClient: yup.object({
        nombre: yup.string().required('Completar este campo es obligatorio').max(90, 'El Nombre no debe exceder los 90 caracteres'),
        slogan: yup.string().max(70, 'El Slogan no debe exceder los 70 caracteres'),
        mail: yup.string().email('Correo Electrónico inválido').required('Completar este campo es obligatorio<').max(150, 'El Correo Electrónico no debe exceder los 150 caracteres'),
        direccion: yup.string().max(150, 'La Dirección no debe exceder los 150 caracteres').required("Completar este campo es obligatorio"),
        hostname: yup.string().matches(/^(?!-)[A-Za-z0-9-]{1,63}(?<!-)$/, 'Hostname inválido').required('El Hostname es requerido')
    })
}

export default schemas